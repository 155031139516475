<template>
  <VuePerfectScrollbar class="e-content__inner e-content__inner--wide">
    <div class="e-content__scroll">
      <div class="e-module e-module--limited">
        <Loading v-if="loading" />

        <template v-else>
          <header class="e-module__head">
            <h1 v-if="sent">
              {{ $t('system.feedback.submit.successHead') }}
            </h1>
            <h1 v-else>
              {{ $t('system.navigation.feedback') }}
            </h1>
          </header>
          <div class="e-module__body">
            <div
              v-if="sent"
              v-html="$t('system.feedback.submit.success')"
            />

            <template v-else>
              <div
                class="e-feedback__entrytext"
                v-html="entryText"
              />

              <!-- Errormessages -->
              <el-alert
                v-for="(item, index) in errors.messages"
                :key="index"
                :title="$t(index)"
                type="error"
                v-html="$t(item)"
              />
              <!-- /Errormessages -->

              <el-form
                ref="feedbackForm"
                :model="formData"
                :rules="rules"
                class="e-feedback"
                label-position="top"
              >
                <el-form-item
                  v-if="!settings.chatDisabled"
                  label="Bezieht sich deine Meinung auf die em@il oder die Chat-Beratung? Bitte wähle aus:"
                  prop="contact"
                  :required="true"
                >
                  <el-radio
                    v-model="formData.contact"
                    label="email"
                  >
                    em@il-Beratung
                  </el-radio>

                  <el-radio
                    v-model="formData.contact"
                    label="chat"
                  >
                    Chat-Beratung
                  </el-radio>
                </el-form-item>

                <el-form-item
                  v-for="item in formFields"
                  :key="item.uuid"
                  :prop="item.uuid"
                  :label="item.question"
                  :required="item.mandatory"
                >
                  <el-input
                    v-if="item.freetext"
                    v-model="formData[item.uuid]"
                    autosize
                    type="textarea"
                  />

                  <FeedbackItem
                    v-else
                    :mandatory="item.mandatory"
                    :item-key="item.uuid"
                    @select="storeFeedback"
                  />
                </el-form-item>

                <div class="e-form__row e-form__row--submit">
                  <div />
                  <el-button
                    :loading="sending"
                    type="primary"
                    @click.prevent="submitForm('feedbackForm')"
                  >
                    {{ $t('system.feedback.send') }}
                  </el-button>
                </div>
              </el-form>
            </template>
          </div>
        </template>
      </div>
    </div>
  </VuePerfectScrollbar>
</template>

<script>
import api from 'api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'
import Loading from 'atoms/Loading/Loading'
import FeedbackItem from 'molecules/FeedbackItem/FeedbackItem'

export default {
  components: {
    VuePerfectScrollbar,
    Loading,
    FeedbackItem
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    formFields: {
      type: Array,
      default: () => []
    },
    entryText: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    sending: false,
    sent: false,
    errors: {},
    formData: {
      contact: null
    },
    rules: {}
  }),

  created() {
    if (this.settings.chatDisabled) {
      this.formData.contact = 'email'
    }
  },

  updated () {
    this.generateRules()
  },

  computed: {
    ...mapGetters(['settings'])
  },

  methods: {
    storeFeedback (data) {
      this.formData[data.key] = data.choice
    },

    generateRules () {
      this.rules['contact'] = [{
        required: true,
        message: this.$t('system.feedback.pleaseAnswerThisQuestion')
      }]
      for (const item of this.formFields) {
        if (item.mandatory) {
          this.rules[item.uuid] = [
            {
              required: true,
              message: this.$t('system.feedback.pleaseAnswerThisQuestion')
            }
          ]
        }
      }
      // console.log(this.rules)
    },

    submitForm (formName) {
      this.errors.messages = []

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendForm()
        } else {
          this.errors.status = 400
          this.errors.messages = ['system.feedback.pleaseAnswerAllQuestions']
          this.$forceUpdate()
        }
      })
    },

    async sendForm () {
      this.sending = true
      this.sent = false

      try {
        const data = Object.assign({}, this.formData)
        await api.call('sendFeedback', null, data)

        this.sent = true
      } catch (error) {
        this.$error(error)
        if (error.messages) {
          this.errors = error
        }
      } finally {
        this.sending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_assets/feedback';
</style>
