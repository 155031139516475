<template>
  <FeedbackForm
    :loading="loading"
    :entry-text="entryText"
    :form-fields="formFields"
  />
</template>

<script>
import api from 'api'
import FeedbackForm from 'organisms/Forms/Feedback/Feedback'

export default {
  components: {
    FeedbackForm
  },

  data: () => ({
    loading: false,
    entryText: '',
    formFields: []
  }),

  created () {
    this.getFormData()
  },

  methods: {
    async getFormData () {
      this.loading = true

      try {
        const response = await api.call('getFeedbackData')
        this.entryText = response.data.results.entryText
        this.formFields = response.data.results.items
      } catch (error) {
        this.$error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .e-loading {
  display: block;
  margin: 0 auto;
}
</style>
